@import "main.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: $black2;
  color: $white;
  font-family: Poppins, sans-serif;
  line-height: 1;
}
body.theme-transition {
  transition: background-color 2s, color 2s;
}
::selection {
  color: black;
  background-color: #f3a100;
}
.contain1 {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 40px;
}
.service_title_area {
  max-width: 900px;
}
.service_title_area .service_subtitle {
  color: $white;
  font-size: 40px;
  line-height: 40px;
  font-weight: $fw-light;
  text-transform: capitalize;
  margin: 0px;
}
.service_title_area .service_title {
  color: $white;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -1px;
  padding-bottom: 40px;
  text-transform: capitalize;
  margin: 0px;
}
.service_title_area .service_disc {
  color: $grey;
  font-weight: $fw-normal;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.5px;
  margin: 0px;
}
.services_list {
  display: flex;
  flex-wrap: wrap;
  width: 102%;
  margin-left: -1%;
  padding: 80px 0;
}
.services {
  width: 48%;
  margin: 56px 1%;
  padding: 56px;
  border-radius: 24px;
  background-color: $service-white;
  box-shadow: 0px 10px 30px 0px $service-shadow;
  border: 2px solid $grey2;
  position: relative;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.services_list .services:hover {
  border-color: $theme-yellow;
  box-shadow: 0 0 10px $theme-yellow;
  .services_img_wrap {
    transform: rotate(-10deg);
  }
}
.services_list .services .services_img_wrap {
  background: $gradient;
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 56px;
  top: -45px;
  transition: transform 0.15s ease-in-out;
}
.services_title {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.3333333;
  margin-top: 24px;
  margin-bottom: 1rem;
  color: $white;
}
.services_desc {
  font-style: normal;
  font-weight: $fw-semibold;
  font-size: 16px;
  line-height: 32px;
  color: $grey;
  margin: 0px;
}
@include tabletMedium {
  .services {
    width: 48%;
    padding: 25px;
  }
  .services_list .services .services_img_wrap {
    left: 25px;
    top: -44px;
    height: 80px;
    width: 80px;
  }
  .services_list {
    padding: 50px 0;
  }
}
@include mobileLarge {
  .service_title_area .service_subtitle {
    font-size: 32px;
    line-height: 32px;
  }
  .service_title_area .service_title {
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .services {
    width: 100%;
    padding: 18px;
    margin: 42px 1%;
  }
  .services_desc {
    line-height: 25px;
  }
}
@include mobileSmall {
  .service_title_area .service_disc {
    line-height: 28px;
  }
}
.design-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.design-content,
.design-image {
  width: 50%;
}
.design_title {
  color: $white;
  font-size: 65px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 14px;
}
.design_subtitle {
  padding: 8px 12px;
  background: $service-white;
  border-radius: 8px;
  margin: 6px 0 32px;
  display: inline-block;
  color: $white;
  box-shadow: 0px 10px 30px 0px var(--service-shadow);
}
.design_desc1,
.design_desc2,
.design_desc3 {
  color: $grey;
  font-size: 16px;
  font-weight: $fw-semibold;
  margin: 0px 0px 16px;
  line-height: 32px;
}
.lazyimage {
  position: relative;
  height: 100%;
  width: 100%;
  img {
    object-fit: contain;
    object-position: center center;
    margin: auto;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
// common class
.LazyImage_img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.design-flow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 102%;
  margin-left: -1%;
  padding: 50px 0;
}
.design_stages {
  display: flex;
  width: 31.3333333333%;
  margin: 56px 1%;
}
@include mobileLarge {
  .design_title {
    font-size: 40px;
  }
  .contain1 {
    padding: 0 25px;
  }
  .design-content,
  .design-image {
    width: 100%;
  }
  .design-content {
    order: 1;
  }
  .design-flow {
    padding: 25px 0;
  }
}
@include mobileSmall {
  .design_desc1,
  .design_desc2,
  .design_desc3 {
    line-height: 28px;
  }
}
//======================contact-us===========================//
.nt-title-area {
  max-width: 900px;
}
.nt-title-area p {
  margin-bottom: 0px;
}
.nt-subtitle {
  font-size: 40px;
  font-weight: $fw-light;
}
.nt-title {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1px;
  padding-bottom: 40px;
}
.nt-desc {
  color: $grey;
  font-weight: $fw-normal;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.5px;
}
// contact-form
.form-block {
  width: 850px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  margin-left: -10px;
}
.form-contact {
  width: 50%;
  padding: 0rem 10px;
  margin-top: 2.3rem;
}
.form-error {
  padding: 10px 0px 0px 10px;
  font-size: 12px;
  color: #af2020;
  margin-bottom: -1.1rem;
}

.form-input input {
  min-height: 65px;
  width: 100%;
  background: $grey2;
  border-radius: 30px;
  padding: 0 20px 0 32px;
  border: 0;
  color: $white;
  outline: none;
}
// put to remove input arrow button
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-button-link {
  height: 55px;
  background: $theme-yellow;
  border-radius: 30px;
  color: $white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  border: 0;
  margin: 20px 10px;
}
@include tabletMedium {
  .form-block {
    width: 100%;
  }
}
@include mobileLarge {
  .nt-subtitle {
    font-size: 32px;
  }
  .nt-title {
    font-size: 40px;
  }
  .form-contact {
    width: 100%;
  }
}
@include mobileMedium {
  .nt-desc {
    line-height: 28px;
  }
  .form-block {
    padding-top: 60px;
  }
  .form-contact {
    padding: 1rem 10px;
    margin-top: 5px;
  }
}
.bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  opacity: 0.05;
}
// work with us button
.work-with-us {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 9;
}
.work-with-us-btn {
  background: $theme-yellow;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  border-radius: 0.3rem;
  padding: 0.75rem 2.5rem;
  cursor: pointer;
}
.work-with-us-details {
  display: none;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border: 1px solid $theme-yellow;
  box-shadow: 0 0 10px $theme-yellow;
  background-color: $black;
  padding: 1.5rem;
  width: 290px;
  animation: fadeinup 500ms forwards;
  z-index: 10;
  a {
    background: $theme-yellow;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 0.3rem;
    padding: 0.75rem 2.75rem;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
  }
}
@keyframes fadeinup {
  0% {
    transform: translateY(90%);
  }
  100% {
    transform: translateY(5%);
  }
}
.work-with-us .work-with-us-btn:hover,
.work-with-us-details {
  display: block;
}
.popup_title {
  font-size: 1.65rem;
  color: $white;
  line-height: 1.2;
  font-weight: 700;
  padding-bottom: 1rem;
}
.popup_text {
  font-weight: $fw-semibold;
  font-size: 0.85rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: $grey;
  margin-bottom: 3rem;
}
@include tabletMedium {
  .work-with-us {
    bottom: 1rem;
  }
}
@include mobileMedium {
  .work-with-us {
    right: 1rem;
  }
}
@include mobileSmall {
  .work-with-us-details {
    right: 1rem;
    bottom: 1rem;
    padding: 1rem;
    width: 280px;
  }
}
//toast message customization
.custom-toast-class {
  background-color: #f3a100;
  padding: 25px;
}

.custom-message-class {
  color: #ffffff;
}
.toast-container {
  margin-top: 60px;
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
::placeholder {
  color: $grey;
}
// toast service
.toast.success {
  background-color: #51a351;
}

.toast.error {
  background-color: #bd362f;
}
.toast {
  background-color: #333;
  color: #fff;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  position: fixed;
  top: 110px;
  left: 89%;
  flex-wrap: wrap;
  transform: translateX(-50%);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}

.toast.show {
  visibility: visible;
  opacity: 1;
}

.toast-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  background: none;
  border: none;
  padding: 14px 16px;
  color: #fff;
}

.copyright {
  margin-top: 25px;
  line-height: 1.8;
  font-size: 14px;
  font-weight: var(--fw-normal);
}
@include mobileLarge {
  .copyright {
    margin-top: 0px;
  }
}
