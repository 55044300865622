$max-desktop: 1600px;
$max-laptop: 1399px;
$max-tablet-large: 1199px;
$max-tablet-medium: 991px;
$max-mobile-large: 767px;
$max-mobile-medium: 575px;
$max-mobile-small: 375px;

@mixin desktopLarge($screen-width: $max-desktop) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin laptop($screen-width: $max-laptop) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin tabletLarge($screen-width: $max-tablet-large) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin tabletMedium($screen-width: $max-tablet-medium) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin mobileLarge($screen-width: $max-mobile-large) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin mobileMedium($screen-width: $max-mobile-medium) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

@mixin mobileSmall($screen-width: $max-mobile-small) {
  @media screen and (max-width: $screen-width) {
    @content;
  }
}

/* How to use*/
// Note : Bootstrap break points taken as reference

// Steps 1 @import "main.scss"; // import in component
// Steps 2 Pass width in mixin if you required

// @include desktopLarge {
//   .abc {
//     color: red;
//   }
// }

// @include desktopLarge(1600px) {
//   .abc {
//     color: red;
//   }
// }

//for animation
@mixin animate($animation, $duration, $method, $direction) {
  animation: $animation $duration $method $direction;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
