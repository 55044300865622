$darkblue-project: var(--darkblue-project);
$white: var(--white);
$white2: var(--white2);
$white3: var(--white3);
$yellow: var(--yellow);
$theme-yellow: var(--theme-yellow);
$theme-black: var(--theme-black);
$black: var(--black);
$black2: var(--black2);
$black4: var(--black4);
$grey: var(--grey);
$grey2: var(--grey2);
$grey3: var(--grey3);
$gradient: var(--gradient);
$service-white: var(--service-white);
$service-shadow: var(--service-shadow);
$fw-light: var(--fw-light);
$fw-normal: var(--fw-normal);
$fw-semibold: var(--fw-semibold);
$cookie-bg: var(--cookie-bg);
.light-mode {
  --darkblue-project: rgba(0, 0, 117, 0.75);
  --white: #000000;
  --white2: #ffffff;
  --white3: #dcdcdc;
  --yellow: #ffa900;
  --theme-yellow: #f3a100;
  --theme-black: rgba(0, 0, 0, 0.95);
  --black: #ffffff;
  --black4: #ffffff;
  --grey: #424141;
  --grey2: rgba(241, 243, 244, 1);
  --grey3: #ffffff;
  --gradient: radial-gradient(circle, #fea100 0, #ffd481 125%);
  --service-white: #ffffff;
  --service-shadow: rgba(0, 0, 0, 0.1);
  --fw-light: 200;
  --fw-normal: 400;
  --fw-semibold: 400;
  --cookie-bg: #f1f1f1;
}
.dark-mode {
  --darkblue-project: rgba(0, 0, 117, 0.75);
  --white: #ffffff;
  --white2: #e1e3e1;
  --white3: #ffffff;
  --yellow: #ffffff;
  --theme-yellow: #f3a100;
  --theme-black: #f3a100;
  --black: #000000;
  --black2: rgba(0, 0, 0, 0.95);
  --black4: #121212;
  --grey: #a0a0a0;
  --grey2: #1e1e1e;
  --grey3: #121212;
  --gradient: radial-gradient(circle, #121212 0, #2e2e2e 100%);
  --service-white: #1e1e1e;
  --service-shadow: none;
  --fw-light: 100;
  --fw-normal: 200;
  --fw-semibold: 300;
  --cookie-bg: rgb(33, 33, 33);
}
